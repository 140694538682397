import React, { Component } from 'react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import Pagination from '../../components/Pagination';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import WarningBox from '../../components/Models/WarningBox';

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'title',
      sortOrder: 'asc',
      page: 0,
      flag: '',
      countTotalEmail: 0,
      emailTemplateList: [],
      selectedEmailId: null,
      searchParams: {},
      isDeleteConfirmOpen: false,
      selectedData: null,
      isActiveOpen: false
    };
  }

  _getAllEmailsTemplates = (searchParams) => {
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.getAllEmailsTemplates(searchParams).then(async (responseJson) => {
      setLoader(false);
      const { status, message, data } = responseJson;
      if (status) {
        this.setState({ emailTemplateList: data });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  _getAllEmailsTemplatesCount = (searchData) => {
    API.getAllEmailsTemplates({ count: 1, ...searchData }).then(async (responseJson) => {
      const { status, data } = responseJson;
      if (status) {
        this.setState({ countTotalEmail: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getAllEmailsTemplates(searchParams);
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getAllEmailsTemplates({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onDeleteAlert = (data, status) => {
    if (status === 1) {
      this.setState({ isDeleteConfirmOpen: true, selectedData: data });
    } else {
      this.setState({ isActiveOpen: true, selectedData: data });
    }
  };

  _onDelete = () => {
    const { selectedData } = this.state;
    const { _id } = selectedData;
    const { setLoader, notify } = this.props;
    const { page } = this.state;
    setLoader(true);
    API.updateIsDeleteEmailTemplate(
      { id: _id }
    )
      .then(async (responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          this._getAllEmailsTemplates({ page });
          this._getAllEmailsTemplatesCount({ page });
          notify({ type: 'success', message });
          this.setState({ isDeleteConfirmOpen: false, selectedData: null });
          this.setState({ isActiveOpen: false, selectedData: null });
        } else {
          notify({ type: 'error', message });
        }
      });
  };

  UNSAFE_componentWillMount() {
    this._getAllEmailsTemplates({});
    this._getAllEmailsTemplatesCount({});
  }

  render() {
    const {
      sortKey, sortOrder, isActiveOpen, countTotalEmail,
      page, emailTemplateList, isDeleteConfirmOpen
    } = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Email Templates</h4>
                    <div className="card-header-action">
                      <Link to="/email-templates/create" className="btn btn-primary">Add Email Template</Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'title' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'title', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Title
                            </th>
                            <th
                              className={`sort ${sortKey === 'subject' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'subject', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Subject
                            </th>
                            <th
                              className={`sort ${sortKey === 'template_trigger' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'template_trigger', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Template Key
                            </th>
                            <th
                              className={`sort ${sortKey === 'status' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'status', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Is Deleted
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !isEmpty(emailTemplateList)
                            && emailTemplateList.map((emailList, key) => (
                              <tr key={key}>
                                <td>{emailList.title}</td>
                                <td>{emailList.subject}</td>
                                <td>{emailList.template_trigger}</td>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input" id={key}
                                      checked={(emailList.status === 2)}
                                      onChange={
                                        () => this._onDeleteAlert(emailList, emailList.status)
                                      }
                                    />
                                    <label htmlFor={key} className="custom-control-label">
                                      &nbsp;
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <Link
                                    to={`/email-templates/${emailList._id}`}
                                    title="Edit"
                                  >
                                    <i className="fas fa-pencil-alt" />
                                  </Link>
                                  &nbsp;&nbsp;
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-center pt-4">
                        <Pagination
                          initialPage={page + 1}
                          items={countTotalEmail}
                          onChangePage={this._onPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Email Template"
          text="Are you sure want to delete this email template?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <WarningBox
          isOpen={isActiveOpen}
          title="Active Email Template"
          text="Are you sure you want to active email template?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withLoader(withNotify(EmailTemplates));
