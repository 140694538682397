import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import ConfirmBox from '../Models/ConfirmBox';
import Pagination from '../Pagination';

class SeoListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      selectedId: null,
      sortKey: 'created_date',
      sortOrder: 'asc',
    };
  }

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const {
      isDeleteConfirmOpen, sortKey, sortOrder
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>SEO Pages List</h4>
              <div className="card-header-action">
                <Link to="/seo-detail/add" className="btn btn-primary">Add SEO Page</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'page_permalink' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'page_permalink', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Seo Page
                      </th>
                      <th
                        className={`sort ${sortKey === 'meta_title_en' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'meta_title_en', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Title (en)
                      </th>
                      <th
                        className={`sort ${sortKey === 'meta_description_en' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'meta_description_en', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Description (en)
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > 0 && data.map((item, key) => (
                        <tr key={key}>
                          <td>
                            {/* {item.page_permalink.split('/')[1].charAt(0).toUpperCase()
                             + item.page_permalink.split('/')[1].substring(1)} */}
                            {item.page_permalink.split('/')[1]}
                          </td>
                          <td>{item.meta_title_en}</td>
                          <td>{item.meta_description_en}</td>
                          <td>
                            <Link to={`/seo-detail/${item._id}`} title="Edit" className="cursor">
                              <i className="fas fa-pencil-alt" />
                            </Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link title="Delete" onClick={() => this._onDeleteAlert(item._id)} className="cursor">
                              <i className="far fa-trash-alt" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      data.length === 0
                      && (
                        <tr>
                          <td colSpan={12}>
                            <center><b>Records not available!</b></center>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete SEO details"
          text="Are you sure want to delete this SEO details?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(SeoListSection));
