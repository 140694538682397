import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import ConfirmBox from '../Models/ConfirmBox';
import Pagination from '../Pagination';

class AddressListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      selectedId: null,
      sortKey: 'address_line_1',
      sortOrder: 'asc'
    };
  }

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage, customerId, customerName
    } = this.props;
    const { isDeleteConfirmOpen, sortKey, sortOrder, } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>
                Address List
                { '  ' }
                {`${customerName ? `(Customer Name : ${customerName} )` : ''} `}
              </h4>
              <div className="card-header-action">
                <Link to={`/customers/${customerId}/address/create`} className="btn btn-primary">Add Address</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'address_line_1' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'address_line_1', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Address
                      </th>
                      <th
                        className={`sort ${sortKey === 'city' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'city', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        City
                      </th>
                      <th
                        className={`sort ${sortKey === 'pincode' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'pincode', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Postcode
                      </th>
                      <th>
                        State
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    data.length > 0 && data.map((address, key) => (
                      <tr key={key}>
                        <td>
                          {address.address_line_1 || '-'}
                        </td>
                        <td>{(address.city) || '-'}</td>
                        <td>{address.pincode || '-'}</td>
                        <td>{address.state || '-'}</td>
                        <td>
                          <Link to={`/customers/${customerId}/address/${address._id}`} title="Edit" className="cursor">
                            <i className="fas fa-pencil-alt" />
                          </Link>
                          <Link title="Delete" onClick={() => this._onDeleteAlert(address._id)} className="cursor">
                            <i className="far fa-trash-alt" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                    {
                    data.length === 0
                    && (
                    <tr>
                      <td colSpan={12}>
                        <center><b>Records not available!</b></center>
                      </td>
                    </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Address"
          text="Are you sure want to delete this address?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(AddressListSection));
