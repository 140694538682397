import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';
import EditSupplierDeliveryTime from '../../components/Models/EditSupplierDeliveryTime';

class SupplierDeliveryTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      selectedData: {},
      isOpenEditSupplierTime: false,
      selectedId: null,
      searchParams: {},
      page: 0,
      countUsers: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: ''
    };
  }

  _getSuppliers = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getSuppliers(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ suppliers: data });
      }
    });
  };

  _countSuppliers = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getSuppliers({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getSuppliers(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getSuppliers(searchParams);
    this._countSuppliers(searchParams);
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getSuppliers({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onOpenAddEditModal = (selectedData, index = null) => {
    this.setState({ selectedData, isOpenEditSupplierTime: true, selectedId: index });
  };

  _editSupplierTime = (data) => {
    const { setLoader, notify } = this.props;
    const { selectedId } = this.state;
    setLoader(true);
    data.id = selectedId;
    API.updateSupplier(data).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getSuppliers({});
        this._countSuppliers({});
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getSuppliers({});
    this._countSuppliers({});
  }

  render() {
    const {
      suppliers, selectedData, isOpenEditSupplierTime, sortKey, sortOrder, search, countUsers, page
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button className="btn btn-primary mr-1" type="button" onClick={() => this._onSearch({ search })}>
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1" type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>All Supplier List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'full_name' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'full_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Full Name
                            </th>
                            <th
                              className={`sort ${sortKey === 'short_code' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'short_code', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Short code
                            </th>
                            <th
                              className={`sort ${sortKey === 'delivery_time' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'delivery_time', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Delivery Code
                            </th>
                            <th
                              className={`sort ${sortKey === 'status' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'status', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Status
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !_.isEmpty(suppliers)
                            && suppliers.map((item, key) => (
                              <tr key={key}>
                                <td>{item.full_name}</td>
                                <td>{item.short_code}</td>
                                <td>{item.delivery_time}</td>
                                <td>{item.status === 1 ? 'Active' : 'Deactive'}</td>
                                <td>
                                  <Link
                                    title="Edit"
                                    data-toggle="modal"
                                    data-target="#modalEditSupplierTime"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        full_name: item.full_name,
                                        short_code: item.short_code,
                                        delivery_time: item.delivery_time,
                                      },
                                      item._id
                                    )}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          }
                          {
                            suppliers.length === 0
                            && (
                              <tr>
                                <td colSpan={12}>
                                  <center><b>Records not available!</b></center>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countUsers}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <EditSupplierDeliveryTime
          isOpen={isOpenEditSupplierTime}
          onCancel={() => {
            this.setState({ isOpenEditSupplierTime: false });
          }}
          editSupplierTime={this._editSupplierTime}
          data={selectedData}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(SupplierDeliveryTime));
