import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router';
import ConfirmBox from '../../components/Models/ConfirmBox';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import AddEditState from '../../components/Models/AddEditState';
import API from '../../utils/utils';
import Pagination from '../../components/Pagination';

class State extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      selectedData: {},
      isOpenAddEditState: false,
      isDeleteConfirmOpen: false,
      selectedId: null,
      searchParams: {},
      page: 0,
      countUsers: 0,
      sortKey: 'created_date',
      sortOrder: 'asc',
      search: ''
    };
  }

  _getStates = (searchParams) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getStates(searchParams).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ states: data });
      }
    });
  };

  _countState = (searchData) => {
    const { setLoader } = this.props;
    setLoader(true);
    API.getStates({ count: 1, ...searchData }).then((responseJson) => {
      setLoader(false);
      const { data, status } = responseJson;
      if (status) {
        this.setState({ countUsers: data });
      }
    });
  };

  _onSort = (sortKey, sortOrder) => {
    let { searchParams } = this.state;
    this.setState({ sortKey, sortOrder });
    searchParams = { ...searchParams, ...{ sortKey, sortOrder } };
    this.setState({ searchParams });
    this._getStates(searchParams);
  };

  _onSearch = (searchData) => {
    let { searchParams } = this.state;
    searchParams.page = 0;
    searchParams = { ...searchParams, ...searchData };
    this.setState({ searchParams, page: 0 });
    this._getStates(searchParams);
    this._countState(searchParams);
  };

  handleKeypress = (e, searchData) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let { searchParams } = this.state;
      searchParams.page = 0;
      searchParams = { ...searchParams, ...searchData };
      this.setState({ searchParams, page: 0 });
      this._getStates(searchParams);
      this._countState(searchParams);
    }
  };

  _onResetSearch = () => {
    this._onSearch({ search: '' });
    this.setState({ search: '' });
  };

  _onPage = (pageParam) => {
    const { searchParams, page } = this.state;
    this.setState({ page: pageParam - 1 });
    if (page !== (pageParam - 1)) {
      this._getStates({ ...searchParams, page: pageParam - 1 });
    }
  }

  _onOpenAddEditModal = (selectedData, index = null) => {
    this.setState({ selectedData, isOpenAddEditState: true, selectedId: index });
  };

  _addEditState = (data) => {
    const { setLoader, notify } = this.props;
    const { selectedData, selectedId } = this.state;
    setLoader(true);
    if (Object.keys(selectedData).length > 0 && selectedId != null) {
      data.id = selectedId;
      API.updateState(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message });
          this._getStates({});
        } else {
          notify({ type: 'error', message });
        }
      });
    } else {
      API.addState(data).then((responseJson) => {
        setLoader(false);
        const { status, message } = responseJson;
        if (status) {
          notify({ type: 'success', message: 'State added successfully.' });
          this._getStates({});
        } else {
          notify({ type: 'error', message });
        }
      });
    }
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onDelete = () => {
    const { selectedId } = this.state;
    const { setLoader, notify } = this.props;
    setLoader(true);
    API.deleteState({ id: selectedId }).then((responseJson) => {
      setLoader(false);
      const { status, message } = responseJson;
      if (status) {
        notify({ type: 'success', message });
        this._getStates({});
        this._countState({});
        this.setState({ isDeleteConfirmOpen: false, selectedId: null });
      } else {
        notify({ type: 'error', message });
      }
    });
  };

  UNSAFE_componentWillMount() {
    this._getStates({});
    this._countState({});
  }

  render() {
    const {
      states, selectedData, isOpenAddEditState, isDeleteConfirmOpen,
      sortKey, sortOrder, search, countUsers, page
    }
      = this.state;
    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>States</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                              placeholder="Search Keyword"
                              type="text"
                              className="form-control"
                              value={search}
                              onChange={({ target: { value } }) => {
                                this.setState({ search: value });
                              }}
                              onKeyDown={(e) => this.handleKeypress(e, { search })}
                            />
                            <label htmlFor="space">&nbsp;</label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="reset">&nbsp;</label>
                            <div className="">
                              <button className="btn btn-primary mr-1" type="button" onClick={() => this._onSearch({ search })}>
                                Search
                              </button>
                              <button
                                className="btn btn-primary mr-1" type="button"
                                onClick={() => this._onResetSearch()}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>All States List</h4>
                    <div className="card-header-action home-banner-button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalAddEditState"
                        onClick={() => this._onOpenAddEditModal(
                          {

                          }
                        )}
                      >
                        Add New State
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-2">
                        <thead>
                          <tr>
                            <th
                              className={`sort ${sortKey === 'name' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'name', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              State
                            </th>
                            <th
                              className={`sort ${sortKey === 'country_name' ? `sort-by-${sortOrder}` : ''} `}
                              onClick={this._onSort.bind(this, 'country_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Country
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !_.isEmpty(states)
                            && states.map((item, key) => (
                              <tr key={key}>
                                <td>{item.name}</td>
                                <td>{item.country_name}</td>
                                <td>
                                  <Link
                                    title="Edit"
                                    data-toggle="modal"
                                    data-target="#modalAddEditState"
                                    onClick={() => this._onOpenAddEditModal(
                                      {
                                        name: item.name,
                                        country_id: item.country_id,
                                      },
                                      item._id
                                    )}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;
                                  <Link
                                    title="Delete"
                                    className="cursor"
                                    onClick={() => this._onDeleteAlert(item._id)}
                                  >
                                    <i className="far fa-trash-alt" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          }
                          {
                            states.length === 0
                            && (
                              <tr>
                                <td colSpan={12}>
                                  <center><b>Records not available!</b></center>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center pt-4">
                      <Pagination
                        initialPage={page + 1}
                        items={countUsers}
                        onChangePage={this._onPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete State"
          text="Are you sure want to delete this state?"
          onConfirm={this._onDelete}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false });
          }}
        />
        <AddEditState
          isOpen={isOpenAddEditState}
          onCancel={() => {
            this.setState({ isOpenAddEditState: false });
          }}
          addEditState={this._addEditState}
          data={selectedData}
        />
      </div>
    );
  }
}
export default withLoader(withNotify(State));
