import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import withNotify from '../../common/withNotify';
import withLoader from '../../common/withLoader';
import ConfirmBox from '../Models/ConfirmBox';
import WarningBox from '../Models/WarningBox';
import Pagination from '../Pagination';

class CustomerListSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteConfirmOpen: false,
      isActiveOpen: false,
      selectedId: null,
      sortKey: 'email',
      sortOrder: 'asc'
    };
  }

  _onActiveDeactiveAlert = (id) => {
    this.setState({ isActiveOpen: true, activeId: id });
  };

  _onPressActiveDeactiveIcon = () => {
    const { onActiveDeactive } = this.props;
    const { activeId } = this.state;
    this.setState({ isActiveOpen: false, activeId: null });
    onActiveDeactive(activeId);
  };

  _onDeleteAlert = (id) => {
    this.setState({ isDeleteConfirmOpen: true, selectedId: id });
  };

  _onPressDeleteIcon = () => {
    const { onDelete } = this.props;
    const { selectedId } = this.state;
    this.setState({ isDeleteConfirmOpen: false, selectedId: null });
    onDelete(selectedId);
  };

  _onSort = (sortKey, sortOrder) => {
    const { onSort } = this.props;
    this.setState({ sortKey, sortOrder });
    onSort({ sortKey, sortOrder });
  };

  render() {
    const {
      data, count, page, onPage
    } = this.props;
    const {
      isDeleteConfirmOpen, sortKey, sortOrder, isActiveOpen
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Customer List</h4>
              <div className="card-header-action">
                <Link to="/customers/create" className="btn btn-primary">Add Customer</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th
                        className={`sort ${sortKey === 'first_name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'first_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        First Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'last_name' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'last_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Last Name
                      </th>
                      <th
                        className={`sort ${sortKey === 'email' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'email', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Email
                      </th>
                      <th
                        className={`sort ${sortKey === 'phone' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'phone', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Phone No
                      </th>
                      {/* <th
                        className={`sort ${sortKey === 'numOfNotify' ?
                        `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'numOfNotify',
                         sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Notify Me
                      </th> */}
                      <th
                        className={`sort ${sortKey === 'numOfWishList' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'numOfWishList', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Wishlist
                      </th>
                      <th
                        className={`sort ${sortKey === 'numOfOrder' ? `sort-by-${sortOrder}` : ''} `}
                        onClick={this._onSort.bind(this, 'numOfOrder', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        Orders
                      </th>
                      <th>Is Activated</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    data.length > 0 && data.map((user, key) => (
                      <tr key={key}>
                        <td>
                          {user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) || '-'}
                        </td>
                        <td>
                          {(user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)) || '-'}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone ? user.phone : 'N/A'}</td>
                        {/* <td>
                          <Link to={`/customers/notifyMe/${user._id}`}
                           title="Notify" className="cursor">
                            {user.numOfNotify}
                          </Link>
                        </td> */}
                        <td>
                          <Link to={`/customers/wishlist/${user._id}`} title="wishlist" className="cursor">
                            {user.numOfWishList}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/customers/orders/${user._id}`} title="Orders" className="cursor">
                            {user.numOfOrder}
                          </Link>
                        </td>
                        <td>
                          <Link
                            title={
                              (user.status === 1)
                                ? 'Active'
                                : (user.status === 0)
                                  ? 'Deactive'
                                  : ''
                            }
                            className="cursor"
                            onClick={() => this._onActiveDeactiveAlert(user._id)}
                          >
                            {
                              (user.status === 1)
                                ? <i className="fas fa-user-check" />
                                : (user.status === 0)
                                  ? <i className="fas fa-user-times" />
                                  : ''
                            }
                          </Link>
                        </td>
                        <td>
                          <Link to={`/customers/${user._id}/address`} title="Address" className="cursor">
                            <i className="fas fa-address-card" />
                          </Link>
                          <Link to={`/customers/${user._id}`} title="Edit" className="cursor">
                            <i className="fas fa-pencil-alt" />
                          </Link>
                          <Link title="Delete" onClick={() => this._onDeleteAlert(user._id)} className="cursor">
                            <i className="far fa-trash-alt" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                    {
                    data.length === 0
                    && (
                    <tr>
                      <td colSpan={12}>
                        <center><b>Records not available!</b></center>
                      </td>
                    </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center pt-4">
                <Pagination
                  initialPage={page + 1}
                  items={count}
                  onChangePage={onPage}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmBox
          isOpen={isDeleteConfirmOpen}
          title="Delete Customer"
          text="Are you sure want to delete this customer?"
          onConfirm={() => this._onPressDeleteIcon()}
          onCancel={() => {
            this.setState({ isDeleteConfirmOpen: false, selectedId: null });
          }}
        />
        <WarningBox
          isOpen={isActiveOpen}
          title="Active/Deactive"
          text="Are you sure you want to continue ?"
          onConfirm={this._onPressActiveDeactiveIcon}
          onCancel={() => {
            this.setState({ isActiveOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withNotify(withLoader(CustomerListSection));
